import React from 'react';
import { classes } from './CartConflictMessage.st.css';
import {
  SectionMessage,
  SectionMessageType,
} from '../SectionMessage/SectionMessage';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { CartConflictMessageDataHooks } from './dataHooks';

interface CartConflictMessageProps {}

export const CartConflictMessage: React.FC<CartConflictMessageProps> = () => {
  const { t } = useTranslation();

  return (
    <div
      className={classes.root}
      data-hook={CartConflictMessageDataHooks.NOTIFICATION_MESSAGE}
    >
      <SectionMessage
        text={t('app.bookings-form.toast.cart-conflict.error')}
        type={SectionMessageType.Error}
        withoutIcon
        buttonText={t('app.bookings-form.cart-conflict.back')}
        buttonOnClick={() => window.history.back()}
      />
    </div>
  );
};
